@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}

.loadingOverlay.visible {
  opacity: 1;
}

.loadingIndicator {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #007bff;
  animation: pulse 1.5s ease-in-out infinite;
}

.loadingText {
  margin-left: 10px;
  font-weight: bold;
}

/* Dialog styles */
.dialogPaper {
  height: calc(100% - 250px);
  max-height: none;
  width: 100%;
  max-width: none;
}

/* Evidence dialog styles */
.questionContainer {
  margin-top: 20px;
}

.questionDetail {
  margin-left: 20px;
}

/* Welcome screen styles */
.welcomeContainer {
  text-align: center;
  padding: 24px;
}

.chartPreview {
  width: 100%;
  max-width: 400px;
  margin: auto;
  height: 150px;
}

/* Main container styles */
.mainContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.mainContainerEmpty {
  opacity: 0.5;
}

/* Chart container styles */
.chartContainer {
  flex: 1;
  width: 100%;
  position: relative;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.chartButton {
  width: 100%;
  margin-bottom: 10px;
  font-weight: bold;
  text-transform: none;
}

.chartWrapper {
  position: relative;
  width: 100%;
  height: 90%;
}

.chartInner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Not enough entries overlay */
.notEnoughEntriesOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
  text-align: center;
}

.overlayIcon {
  font-size: 80px !important;
  color: #3f51b5;
  margin-bottom: 20px;
}

.loadingTextSmaller {
  font-size: smaller;
}
