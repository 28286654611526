.main {
    display: flex;
    justify-content: center;
}

.playButton {
    position: absolute;
    cursor: pointer;
    z-index: 2;
}
.iconContainer {
    position: relative;
    max-width: 100px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.icon>span:nth-child(2) {
    margin-left: -9px;
}

.icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--shade-color-2);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    z-index: 1;
}

.status {
    position: absolute;
    right: calc(50% - 32px);
    bottom: -5px;
}

img.status {
    max-width: 50px;
    max-height: 50px;
    /* circle the image */
    border-radius: 50%;
}

@keyframes glowPulse {
    0% {
        transform: scale(1);
        /*box-shadow: 0 0 5px rgba(0, 255, 0, 0.5), 0 0 10px rgba(0, 255, 0, 0.5);*/
    }
    50% {
        transform: scale(1.2);
        /*box-shadow: 0 0 10px rgba(0, 255, 0, 0.7), 0 0 20px rgba(0, 255, 0, 0.7);*/
    }
    100% {
        transform: scale(1);
        /*box-shadow: 0 0 5px rgba(0, 255, 0, 0.5), 0 0 10px rgba(0, 255, 0, 0.5);*/
    }
}

.glow {
    animation: glowPulse 2s infinite;
}

.green {
    color: green;
    padding: 0;
}
.red {
    color: var(--error-color);
    padding: 0;
}

.green.withImg {
    height: 15px!important;
    width: 15px!important;
    background-color: white;
    border-radius: 50%;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
    filter: grayscale(100%);
}


.spinning {
    animation: spin 2s linear infinite;
}

.dialogTxt {
    text-align: justify;
    margin: 20px!important;
}

.dialogMainParent :global(.MuiPaper-root) {
    overflow: unset;
}

.dialogMain {
    padding: 0!important;
    position: relative;
    overflow: visible;
}

.oneThingContainer {
    position: absolute;
    top: -40px;
    right: -30px;
    z-index: 2;
    padding: 10px;
    overflow: visible;
}

.errorMessage {
    color: red;
    font-family: Consolas, serif;
    padding-bottom: 4px;
}

div.dialogImg {
    /* ensure to wrap the text */
    margin-left: 20px;
    margin-right: 20px;
    border: 1px solid red;
    padding: 4px 4px 20px;
}

.dialogImg {
    max-width: calc(min(70vh, 50vw));
    /* make sure to always keep square */
    max-height: calc(min(70vh, 50vw));
    padding-right: 20px;
    float: left;
}


@media screen and (max-aspect-ratio: 4/7) {
    .dialogTxt {
        padding-right: unset;
    }
    /* make image take the whole width now */
    .dialogImg {
        max-width: 100%;
        max-height: 100%;
    }

}

.refreshIcon {
    position: absolute;
    top: 5px;
    left: 5px;
}

.inappropriateDialog button {
    text-align: right;
}
.buttonParent {
    width: 100%;
    text-align: right;
    padding-top: 20px;
}
.privacyNote {
    font-size: smaller;

}

.oneThingIcon div {
    display: inline-flex;
    border: none;
    box-shadow: none;
    align-items: center;
    padding: 0;
    margin: 0;
}

.oneThingIcon button {
    border: none;
    padding: 0;
    margin: 0 8px;
    width: 24px;
    height: 24px;
}
.oneThingIcon div img {
    max-height: 24px;
    max-width: 24px;
}

div.inappropriate {
    font-size: small;
    text-align: right;
    bottom: 0;
    right: 0;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 4px;
}
div.inappropriate svg {
    margin-left: 3px;
    margin-right: 3px;
    width: 18px;
    cursor: pointer;
}
/* Third SVG as no margin-right */
div.inappropriate svg:nth-child(3) {
    margin-left: 12px;
    margin-right: 0;
}
div.inappropriate svg:nth-child(4) {
    margin-left: 0;
}

.refreshIcon svg {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
}

/* Highlight the icon when hovering over it */
.refreshIcon svg:hover {
    background-color: rgba(255, 255, 255, 0.9);
}

.shareDialog {

}

.sharePrivacyNoteTitle {
    font-weight: bold;
    font-size: small;
}
.shareWhat div, .shareHow {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.sharePreview {
    width: 256px;
    height: 128px;
    max-width: 256px;
    max-height: 128px;
    overflow: clip;
    flex-direction: row;
    margin-top: 20px;
    font-size: 8px;
    border: 1px solid gray;
}
.sharePreview div {
    width: 128px;
    height: 128px;
    min-width: 128px;
    min-height: 128px;
    max-width: 128px;
    max-height: 128px;
}

.shareButton {
    padding: 10px !important;
}

.shareHow :global(.MuiButtonBase-root) {
    min-width: unset;
    color: black;
    margin-left:unset;
    margin-top: -4px;
}

.shareHow button {
    margin-left: 10px;
    margin-right: 10px;
}

.shareWhat {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.shareWhat, .shareHow {
    margin-bottom: 20px;
}
.sharePrivacyNoteNote {
    font-size: small;
}
