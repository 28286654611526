.floatingToolbar {
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    user-select: none;
    touch-action: none;
    transition: box-shadow 0.2s;
}

.oneThingButton {
    width: 56px;
    height: 56px;
    padding: 0;
    overflow: hidden;
}

.oneThingImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
